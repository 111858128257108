export const environment = {
  production: true,
  offlineMode: false,
  apiHost: "https://api.nickelled.com",
  frontHost: "https://front.nickelled.com",
  notificationServiceHost: "https://notify.nickelled.com",
  makeHost: "https://make.nickelled.com",
  segmentAPIKey: "4K5w4inWonKL7o3kpd8LUebd8xv27fpw",
  intercomAppId: "ii6t4rrk",
  bugsnagAPIKey: "c419537c9bafac98f14a589305210e82",
  stripe: {
    publishableKey: "pk_live_x1X7yB3bZXi1MN7LEcTlee4f",
    monthlyPlan: "price_1Kt9gU29MmtJoRiVdd9nyx2l",
    annualPlan: "price_1Kt9gU29MmtJoRiVaxUxAwaN"
  },
  portal: {
    appId: "dummy",
  },
  launchers: {
    js: "https://cdn.nickelled.com/launchers-2.min.js",
    appId: "nickelled-865564",
    apiUrl: "https://api.nickelled.com",
    trackingUrl: "https://tracking.nickelled.com/tracking",
  },
  extensionId: "cdpclbklgmofgohfeabojcokbmeophlh",
  launchDarklyClientId: "61b9cca2da547c0fdd27e6bc",
};
